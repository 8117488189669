@font-face {
    font-family: 'torito';
    src:  url('../fonts/torito.eot?9w19ib');
    src:  url('../fonts/torito.eot?9w19ib#iefix') format('embedded-opentype'),
        url('../fonts/torito.ttf?9w19ib') format('truetype'),
        url('../fonts/torito.woff?9w19ib') format('woff'),
        url('../fonts/torito.svg?9w19ib#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="torito-icon-"], [class*="torito-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'torito' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 20px;


    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.torito-icon-coberturas:before {
    content: "\e900";
}
.torito-icon-como_usar:before {
    content: "\e901";
}
.torito-icon-descripcion:before {
    content: "\e902";
}
.torito-icon-exclusiones:before {
    content: "\e903";
}
.torito-icon-accident:before {
    content: "\e904";
}
.torito-icon-objects:before {
    content: "\e905";
}
.torito-icon-mission:before {
    content: "\e907";
}
.torito-icon-rank:before {
    content: "\e908";
}
.torito-icon-approved:before {
    content: "\e906";
}
.torito-icon-gift:before {
content: "\e909";
}
.torito-icon-profile:before {
    content: "\e90a";
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  background-color: #eeeeee;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
.animated-page-wrapper {
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  height: 100%;
}
.right{
	text-align: right;
}
.pre{
	margin-top: 12px;
	margin-bottom: 12px;
	background-color: #f5f5f5;
	text-align: center;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 8px;
	padding-bottom: 8px;
	table {
		width: 100%;
		table-layout: fixed;
		thead{
			tr{
				border-bottom: 1px #d8d8d8 solid;
				th{
					text-align: left;
					font-size: 14px;
					font-weight: 500;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.71;
					letter-spacing: normal;
					color: rgba(0, 0, 0, 0.87);
				}
			}
		}
		tbody{
			tr{
				td{
					text-align: left;
					font-size: 14px;
					font-weight: normal;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.43;
					letter-spacing: normal;
					color: rgba(0, 0, 0, 0.87);
					white-space: pre-wrap; /* css-3 */
					white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
					white-space: -pre-wrap; /* Opera 4-6 */
					white-space: -o-pre-wrap; /* Opera 7 */
					word-wrap: break-word; /* Internet Explorer 5.5+ */
				}
			}
		}
	}
}


//util padding
$listPadding: (
  padding-left: 'pl',
  padding-right: 'pr',
  padding-top: 'pt',
  padding-bottom: 'pb'
);

@each $property, $value in $listPadding
{
	@for $i from 1 through 90 {
		.#{$value}-#{$i} {
			#{$property}: #{$i}px !important;
		}
	}
	// cleaning padding
	@for $i from 1 through 90 {
		.#{$value}-clear {
			#{$property}: 0;
		}
	}
}
//util margin
$listMargin: (
  margin-left: 'ml',
  margin-right: 'mr',
  margin-top: 'mt',
  margin-bottom: 'mb'
);

@each $property, $value in $listMargin
{
	@for $i from 1 through 90 {
		.#{$value}-#{$i} {
			#{$property}: #{$i}px !important;
		}
	}
	// cleaning padding
	@for $i from 1 through 90 {
		.#{$value}-clear {
			#{$property}: 0;
		}
	}
}


@for $i from 8 through 40 {
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }
}


$fontWeights: (
  fw-200: 200,
  fw-500: 500,

);


@each $property, $value in $fontWeights
{
	.#{$property} {
		font-weight: #{$value} !important;
	}

}
